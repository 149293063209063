var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResizeHandler,
          expression: "onResizeHandler",
        },
      ],
      staticClass: "pa-1 product-view",
      style: "height: " + (_vm.windowSize.y + 30) + "px; position: 'relative'",
      attrs: { flat: "" },
    },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: {
                  size: 70,
                  width: 7,
                  color: "primary",
                  indeterminate: "",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-form",
            {
              ref: "pickForm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0 font-weight-bold",
                      attrs: { cols: "6" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("message.layout.orderno")) +
                          ": " +
                          _vm._s(_vm.orderModel.number)
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0 text-right",
                      attrs: { cols: "6" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("message.order.delivery")) +
                          ": " +
                          _vm._s(
                            _vm._f("dateFormat")(_vm.orderModel.deliverydate)
                          )
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pl-3 font-weight-bold",
                      attrs: { cols: "12" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("message.projects.customer")) +
                          ": " +
                          _vm._s(_vm.orderModel.customer_name || "")
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pr-0 pb-0 mt-1",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("barcode-scanner", {
                        attrs: {
                          search: _vm.scanCode,
                          scannerPayload: _vm.scannerPayload,
                          loading: _vm.productLoading,
                        },
                        model: {
                          value: _vm.scanCode,
                          callback: function ($$v) {
                            _vm.scanCode = $$v
                          },
                          expression: "scanCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.productFound
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-1 pb-1 font-weight-bold",
                          attrs: { cols: "12" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("message.equipments.product")) +
                              ": " +
                              _vm._s(_vm.productInfo.description || "") +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.productFound && _vm.productInfo.isbatch
                    ? [
                        _c(
                          "v-col",
                          { staticClass: "pt-0 pb-0", attrs: { cols: "7" } },
                          [
                            _c("v-autocomplete", {
                              ref: "batchRef",
                              attrs: {
                                items: _vm.getbatch,
                                "item-text": "batchnumberpwa",
                                "item-value": "batchnumberpwa",
                                "hide-details": "",
                                outlined: "",
                                loading: _vm.loadingBatch,
                                label: _vm.$t("message.product.batch"),
                                flat: "",
                                autocomplete: "off",
                                dense: "",
                                rules: _vm.$_requiredValidation,
                              },
                              on: { change: _vm.checkTheBatchnumber },
                              model: {
                                value: _vm.selectBatch,
                                callback: function ($$v) {
                                  _vm.selectBatch = $$v
                                },
                                expression: "selectBatch",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.moreLocationsFound
                          ? _c(
                              "v-col",
                              {
                                staticClass: "pl-0 pt-0 pb-0",
                                attrs: { cols: "5" },
                              },
                              [
                                _c("v-autocomplete", {
                                  ref: "moreLocation",
                                  attrs: {
                                    items: _vm.batchLocations,
                                    "item-text": _vm.getItemText,
                                    "item-value": "locationname",
                                    "hide-details": "",
                                    outlined: "",
                                    loading: _vm.loadingLocation,
                                    label: _vm.$t("message.product.scanLoc"),
                                    flat: "",
                                    autocomplete: "off",
                                    dense: "",
                                    rules: _vm.$_requiredValidation,
                                  },
                                  on: { change: _vm.checkIfLocationValid },
                                  model: {
                                    value: _vm.selectedLocation,
                                    callback: function ($$v) {
                                      _vm.selectedLocation = $$v
                                    },
                                    expression: "selectedLocation",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.productFound && !_vm.productInfo.isbatch
                    ? _c(
                        "v-col",
                        { staticClass: "pt-0 pb-0", attrs: { cols: "8" } },
                        [
                          _c("v-autocomplete", {
                            ref: "moreLocation",
                            attrs: {
                              items: _vm.listOfLocations,
                              "item-text": _vm.getItemText,
                              "item-value": "locationname",
                              "hide-details": "",
                              outlined: "",
                              loading: _vm.loadingLocation,
                              label: _vm.$t("message.product.scanLoc"),
                              flat: "",
                              autocomplete: "off",
                              dense: "",
                              rules: _vm.$_requiredValidation,
                            },
                            on: { change: _vm.checkIfLocationValid },
                            model: {
                              value: _vm.selectedLocation,
                              callback: function ($$v) {
                                _vm.selectedLocation = $$v
                              },
                              expression: "selectedLocation",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm.productFound
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-1", attrs: { cols: "9" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center pa-0",
                                  attrs: { cols: "3" },
                                  on: { click: _vm.decreaseQty },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "info",
                                        fab: "",
                                        "x-small": "",
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-minus")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    ref: "qtyRef",
                                    staticClass: "text-right",
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      type: "number",
                                      autocomplete: "off",
                                      rules: _vm.$_qtyValidation,
                                      placeholder: _vm.$t(
                                        "message.product.qty"
                                      ),
                                    },
                                    model: {
                                      value: _vm.pickQty,
                                      callback: function ($$v) {
                                        _vm.pickQty = $$v
                                      },
                                      expression: "pickQty",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center pa-0",
                                  attrs: { cols: "3" },
                                  on: { click: _vm.increaseQty },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "info",
                                        fab: "",
                                        "x-small": "",
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-1 mt-1", attrs: { cols: "3" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                dark: "",
                                small: "",
                                loading: _vm.saveLoading,
                              },
                              on: { click: _vm.pickQtyOnOrder },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("message.order.pick")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "highlight pt-0 pb-0 pl-1 mt-2" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 font-weight-bold",
                  attrs: { cols: "4" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.toPick")) + ":")]
              ),
              _c(
                "v-col",
                { staticClass: "pa-0 font-weight-bold", attrs: { cols: "3" } },
                [_vm._v(_vm._s(_vm.$t("message.order.location")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.avail")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 text-right font-weight-bold",
                  attrs: { cols: "3" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.ordered")))]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "pa-0" })],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.listOfProducts.topickproducts, function (product, index) {
            return _c(
              "v-row",
              { key: index },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 pl-3 text-truncate",
                    class: {
                      "pb-1":
                        index === _vm.listOfProducts.topickproducts.length - 1,
                    },
                    attrs: { cols: "4", title: product.number },
                  },
                  [_vm._v(" " + _vm._s(product.number) + " ")]
                ),
                _c(
                  "v-col",
                  { staticClass: "pa-0", attrs: { cols: "5" } },
                  _vm._l(product.locations, function (location, index) {
                    return _c(
                      "v-row",
                      { key: index + "_location" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pa-0 text-truncate",
                            attrs: { cols: "6", title: location.locationname },
                          },
                          [_vm._v(_vm._s(location.locationname))]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "pa-0 pl-3 text-right text-truncate",
                            attrs: { cols: "6", title: location.availableqty },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("absoluteNumber")(
                                    location.availableqty
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "pa-0 pl-3 text-right", attrs: { cols: "3" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("absoluteNumber")(product.orderedqty)) +
                        " "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "caption text-truncate",
                        attrs: { title: product.unitname },
                      },
                      [_vm._v(_vm._s(product.unitname))]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "v-card-text",
        {
          staticClass: "highlight pt-0 pb-0 pl-1 mt-2",
          attrs: { tabindex: "0" },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 font-weight-bold",
                  attrs: { cols: "3" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.picked")) + ":")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pr-2 text-center font-weight-bold",
                  attrs: { cols: "3" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.location")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.product.qty")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.avail")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.ordered")))]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "pa-0" })],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.listOfProducts.pickedproducts, function (product, index) {
            return _c(
              "v-row",
              {
                key: index,
                staticClass: "pb-3",
                on: {
                  click: function ($event) {
                    return _vm.scanProductByClick(product.productnumber)
                  },
                },
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 pl-3 text-truncate",
                    class: {
                      "pb-1":
                        index === _vm.listOfProducts.pickedproducts.length - 1,
                    },
                    attrs: { cols: "3", title: product.number },
                  },
                  [_vm._v(" " + _vm._s(product.number) + " ")]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 text-center text-truncate",
                    attrs: { cols: "3", title: product.locationname },
                  },
                  [_vm._v(_vm._s(product.locationname))]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 text-right text-truncate",
                    attrs: { cols: "2", title: product.qty },
                  },
                  [_vm._v(_vm._s(_vm._f("absoluteNumber")(product.qty)))]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 pl-3 text-right text-truncate",
                    attrs: { cols: "2", title: product.productavaiable },
                  },
                  [
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("absoluteNumber")(product.productavaiable)
                        )
                      ),
                    ],
                  ],
                  2
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "pa-0 pl-3 text-right text-truncate",
                    attrs: { cols: "2" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("absoluteNumber")(product.orderedqty)) +
                        " "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "caption",
                        attrs: { title: product.unitname },
                      },
                      [_vm._v(_vm._s(product.unitname))]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }